/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { Content, SectionColumn } from 'src/styles/layout'
import {
  ACCESSDENIED,
  ACCOUNT_DETAIL,
  COMPLIANCE,
  COMPLIANCE_INFO,
  CREATE_COMPLAINT,
} from 'src/routes'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
  Skeleton,
} from '@mui/material'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  BcoValuesResponseType,
  HasAcknowledgementResponse,
  IsSellerBCOEnabledResponse,
} from 'src/graphql/models/Complaint'
import {
  COMPLAINT_EXTERNAL_AGENCY_COMPLAINT,
  GET_BCO_BUYERS,
  GET_BCO_INTERNAL,
  GET_BCO_SELLER,
  GET_HAS_ACKNOWLEDGEMENT,
  GET_IS_SELLER_BCO_ENABLED,
} from 'src/graphql/operations/queries/complaint'
import { UserType } from 'src/graphql/models/User'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { BUYER, INTERNAL, SELLER } from 'src/configs/AuthService'
import { complaintMutation } from 'src/graphql/operations/mutations'
import {
  Colors,
  DataTable,
  DataTableState,
  DatePicker,
  Icon,
  renderValueOrNA,
} from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'
import { renderCapitalize, renderDate } from 'src/utils/formatKendoColumns'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import SectionCards from './components/SectionCards'
import BCOPreview from './components/BCOPreview'
import BCOCards from './components/BCOCards'
import { GridItemsHeader } from './styles'
import { ModalHeader } from '../BuyerDetail/styles'
import {
  useCustomLazyQuery,
  useCustomQuery,
} from 'src/infra/react-query-wrapper'
import {
  exportComplaint,
  getBuyerAndSellerBco,
  getComplaintData,
  getSellerFilterComplaint,
} from 'src/data/features/get/complaint/complaint'
import {
  BuyerAndSellerBcoResponse,
  ComplaintDataResponse,
  SellerFilterComplaintResponse,
} from 'src/data/features/get/complaint/types'
import { getStandardUri } from 'src/utils/common'

const Compliance: React.FC = () => {
  const { status }: any = useParams()
  const [hasAcknowledgement, setHasAcknowledgement] = useState(false)
  const [showAcknowledgement, setShowAcknowledgement] = useState(false)
  const [showFilterCompliance, setShowFilterCompliance] = useState(false)
  const [cardSelected, setCardSelected] = useState(status)
  const [canAccessBco, setCanAccessBco] = useState(true)
  const [accessDenyBco, setAccessDenyBco] = useState(false)
  const [buyerIdSeleted, setBuyerIdSeleted] = useState('')
  const [sellerIdSeleted, setSellerIdSeleted] = useState('')
  const [sortBy, setSortBy] = useState('nameAsc')
  const [sellerFilter, setSellerFilter] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [agencyName, setAgencyName] = useState('')
  const [selectedFilters] = useState<{}>({})
  const history = useHistory()
  const [seletedBco, setSeletedBco] = useState(false)
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)
  const [options, setOptions] = useState<string[]>([])
  const ability = useContext(AbilityContext)

  const isInternal = userType?.userType === INTERNAL
  const isSeller = userType?.userType === SELLER
  const isBuyer = userType?.userType === BUYER

  const sortByTypes = [
    {
      label: 'Name Ascending',
      value: 'nameAsc',
    },
    {
      label: 'Name Descending',
      value: 'nameDesc',
    },
    {
      label: 'ACR Ascending',
      value: 'bcrAsc',
    },
    {
      label: 'ACR Descending',
      value: 'bcrDesc',
    },
  ]

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const { profileBusiness, profileClient } = useContext(AuthContext)

  const canShowSellerDrop = () => {
    const businessLength = profileBusiness.filter(
      (x: { Type: any }) =>
        x.Type.toLocaleLowerCase() === SELLER.toLocaleLowerCase()
    ).length
    return businessLength > 1
  }

  const { useSaveAcknowledgeBco } = complaintMutation

  const getQueryGraphql = () => {
    if (isBuyer) return GET_BCO_BUYERS
    if (isSeller) return GET_BCO_SELLER
    return GET_BCO_INTERNAL
  }

  const {
    executeQuery: getUriExportComplaint,
    isFetching: loadingUriComplaint,
  } = useCustomLazyQuery<any>(
    ['exportComplaint'],
    async () => {
      return exportComplaint(agencyName, sellerFilter, startDate, endDate)
        .then((data: string) => {
          if (data) {
            window.location.href = data
          }
          return data
        })
        .finally(() => setShowFilterCompliance(false))
    },
    { enabled: false, cacheTime: 0 }
  )

  const [getHasAcknowledgement, { data: hasAcknowledgementResponse }] =
    useLazyQuery<HasAcknowledgementResponse>(GET_HAS_ACKNOWLEDGEMENT, {
      fetchPolicy: 'no-cache',
    })

  const [getIsSellerBcoEnabled, { data: isSellerBcoEnabled }] =
    useLazyQuery<IsSellerBCOEnabledResponse>(GET_IS_SELLER_BCO_ENABLED, {})

  const { data: complaintData, isFetching: loadingComplaints } =
    useCustomQuery<ComplaintDataResponse>(
      ['getComplaintData', cardSelected, gridState],
      async () => getComplaintData(cardSelected, JSON.stringify(gridState)),
      {
        cacheTime: 0,
      }
    )

  const { data: sellerFilterData } = useCustomQuery<
    SellerFilterComplaintResponse[]
  >(['getSellerFilterComplaint'], async () => getSellerFilterComplaint(), {
    cacheTime: 0,
  })

  const [
    getBcoValuesResponse,
    { data: bcoValueData, loading: loadingbcoValueData },
  ] = useLazyQuery<BcoValuesResponseType>(getQueryGraphql(), {
    variables: {
      sortBy,
    },
  })

  const { data: buyerAndSellerBcoData } =
    useCustomQuery<BuyerAndSellerBcoResponse>(
      ['getBuyerAndSellerBco'],
      async () => getBuyerAndSellerBco(),
      {
        cacheTime: 0,
      }
    )

  const sortCard = (sortCardBy: string) => {
    setSortBy(sortCardBy)
    getBcoValuesResponse()
  }

  const handleCardsSelected = useCallback(
    (type: any) => {
      setCardSelected(type)
      history.push(getStandardUri(`${COMPLIANCE}/${type}`))
    },
    [history]
  )
  const handleBcoSelected = (buyerId: string, sellerId: string) => {
    setBuyerIdSeleted(buyerId)
    setSellerIdSeleted(sellerId)
    setSeletedBco(!seletedBco)
  }

  useEffect(() => {
    if (isSeller) {
      getIsSellerBcoEnabled()
      getHasAcknowledgement()
    }
  }, [getHasAcknowledgement, getIsSellerBcoEnabled, isSeller])

  useEffect(() => {
    setCardSelected(status)
  }, [status])

  const [loadBuyerAgency, { data: nameList, loading: loadingNameList }] =
    useLazyQuery<any>(COMPLAINT_EXTERNAL_AGENCY_COMPLAINT, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    })
  useEffect(() => {
    if (nameList?.nameList) {
      setOptions(nameList?.nameList || [])
    }
  }, [nameList])

  useEffect(() => {
    if (cardSelected === 'bco') {
      getBcoValuesResponse()
      if (isSeller && hasAcknowledgementResponse) {
        setHasAcknowledgement(hasAcknowledgementResponse?.hasAcknowledgement)
        setShowAcknowledgement(!hasAcknowledgementResponse?.hasAcknowledgement)
      }
    }
  }, [cardSelected, getBcoValuesResponse, hasAcknowledgementResponse, isSeller])

  useEffect(() => {
    if (bcoValueData?.data && bcoValueData?.data?.length > 0) {
      setBuyerIdSeleted(bcoValueData?.data[0].buyerBCOMetricId)
      setSellerIdSeleted(bcoValueData?.data[0].sellerId)
    }
  }, [bcoValueData])

  const GridColumns: any[] = [
    {
      title: 'ID',
      field: 'id',
      show: true,
      width: 90,
    },
    {
      title: 'Status',
      field: 'status',
      render: renderCapitalize,
      show: true,
      width: 110,
    },
    {
      title: 'Assigned To',
      field: 'assignedUserName',
      show: cardSelected === 'open',
      render: renderValueOrNA,
      width: 185,
    },
    {
      title: 'Loan ID',
      field: 'loanId',
      show: true,
      width: 130,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowId ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowId}`
                )}
              >
                {props.dataItem.loanId}
              </Button>
            ) : (
              <>{props.dataItem.loanId}</>
            )}
          </td>
        )
      },
    },
    {
      title: 'PID',
      field: 'pId',
      show: true,
      width: 90,
    },
    {
      title: 'Seller',
      field: 'sellerName',
      show: true,
      width: 150,
    },
    {
      title: 'Buyer',
      field: 'buyerName',
      show: true,
      width: 150,
    },
    {
      title: 'Agency',
      field: 'agency',
      show: true,
      width: 120,
    },
    {
      title: 'Submitted',
      field: 'submitted',
      render: renderDate,
      show: true,
      width: 150,
    },
  ]
  const onCreateComplaint = () => history.push(getStandardUri(CREATE_COMPLAINT))
  const { saveAcknowledgeBco, loading: savingAcknowledgment } =
    useSaveAcknowledgeBco({
      onCompleted: () => {
        setShowAcknowledgement(false)
      },
    })

  const onAcceptClick = () => {
    setHasAcknowledgement(true)
    saveAcknowledgeBco({
      refetchQueries: ['HasAcknowledgement'],
    })
  }

  const onCancelClick = () => {
    setHasAcknowledgement(false)
    setShowAcknowledgement(false)
  }
  const AcknowledgementDialog = () => {
    return (
      <Dialog open={showAcknowledgement} maxWidth="sm" fullWidth>
        <Box
          style={{
            backgroundColor: Colors.primary,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => onCancelClick()}
            style={{
              position: 'absolute',
              right: 5,
              top: 5,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
          <DialogTitle>
            <Typography variant="h1" style={{ color: 'white' }}>
              Buyer Compliance Overview Acknowledgment
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent style={{ margin: '4px 0px 5px 0px' }}>
          <Typography variant="body2" style={{ marginTop: '10px' }}>
            In order to view Buyer's Compliance Overview, please read and accept
            the terms below:
          </Typography>
          <Typography variant="body2" style={{ marginTop: '20px' }}>
            Company understands that Buyer Due Diligence documentation is
            provided by Consultant without representation, warranty, or
            guarantee. Company understands that it is Company’s sole
            responsibility to review the due diligence material provided and
            perform its own independent analysis. In no event shall Consultant
            be liable for the representations, statements or material provided
            by a Buyer or prospective Buyer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onCancelClick()}
            color="secondary"
            variant="outlined"
            disabled={savingAcknowledgment}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onAcceptClick()}
            color="primary"
            variant="contained"
            disabled={savingAcknowledgment}
          >
            I Accept
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderDropDownSeller = () => {
    if (isInternal || canShowSellerDrop())
      return (
        <Grid item xs={3} sm={3}>
          <Box mt={4} display="flex-start">
            <TextField
              id="seller"
              name="seller"
              select
              label="Seller"
              variant="standard"
              fullWidth
              size="small"
              value={sellerFilter}
              onChange={({ target: { value } }): void => {
                setSellerFilter(value)
              }}
            >
              {sellerFilterData?.map((option) => (
                <MenuItem key={option.name} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>
      )
    return null
  }
  const FilterExportComplainceDialog = () => {
    return (
      <Dialog open={showFilterCompliance} maxWidth="md" fullWidth>
        <ModalHeader
          py={3}
          px={5}
          m={0}
          bgcolor="green"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="white"
        >
          <Typography color="inherit" variant="h5">
            Export to Excel
          </Typography>
          <IconButton
            size="small"
            onClick={() => setShowFilterCompliance(false)}
          >
            <Icon name="Close" color="white" />
          </IconButton>
        </ModalHeader>
        <DialogContent>
          <Grid container direction="row" alignItems="end">
            <Grid item xs={3} sm={3}>
              <Box display="flex-start">
                <DatePicker
                  margin="normal"
                  id="startDate"
                  label="Start date"
                  value={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  country={profileClient?.Country}
                />
              </Box>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Box display="flex-start">
                <DatePicker
                  margin="normal"
                  id="endDate"
                  label="End date"
                  value={endDate}
                  onChange={(date: any) => setEndDate(date)}
                  country={profileClient?.Country}
                />
              </Box>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Box mt={4} display="flex-start">
                <Autocomplete
                  style={{ width: '90%' }}
                  options={options}
                  clearOnEscape
                  freeSolo
                  onSelect={(e: any) => {
                    if (e?.target?.value) setAgencyName(e.target.value)
                    else setAgencyName('')
                  }}
                  value={agencyName || ''}
                  onInputChange={(event, value) => {
                    if (value || value === '') setAgencyName(value)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="agency"
                      label="Agency"
                      fullWidth
                      value={agencyName}
                      onChange={(e) => {
                        handleChange(e.target.value)
                        loadBuyerAgency({
                          variables: {
                            nameFilter: e.target.value,
                          },
                        })
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingNameList ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
            {renderDropDownSeller()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowFilterCompliance(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onDownload()}
            color="primary"
            variant="contained"
            disabled={loadingUriComplaint}
            startIcon={
              loadingUriComplaint && (
                <CircularProgress size={15} color="primary" />
              )
            }
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderMyBco = () => {
    return (
      <Content>
        <BCOPreview buyerIdGuid="" sellerIdGuid="" />
      </Content>
    )
  }
  const renderBco = () => {
    if (accessDenyBco) {
      window.location.href = `${ACCESSDENIED}`
    }
    if (!canAccessBco) {
      window.location.href = getStandardUri(`${COMPLIANCE}/my-bcr`)
    }
    if (loadingbcoValueData)
      return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Grid>
      )

    if (isSeller && !isSellerBcoEnabled?.isSellerBCOEnabled)
      return (
        <Box
          py={3}
          px={5}
          mb={4}
          bgcolor="white"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="black"
        >
          <Typography variant="h1" style={{ fontWeight: 400 }}>
            BCO not available{' '}
          </Typography>
        </Box>
      )
    if (isSeller && showAcknowledgement) {
      return AcknowledgementDialog()
    }
    if (isSeller && !hasAcknowledgement) {
      return null
    }

    return (
      <Paper
        style={{
          marginTop: '10px',
          marginLeft: '10px',
          marginBottom: '10px',
        }}
      >
        {renderBcoData()}
      </Paper>
    )
  }
  const renderInternalButtons = () => {
    if (isInternal) {
      return (
        <Grid container direction="row">
          <Grid item xs={2} sm={2} style={{ marginBottom: '10px' }}>
            <Box mt={5} display="flex-end">
              <TextField
                id="sortBy"
                name="sortBy"
                select
                label="Sort By:"
                variant="filled"
                fullWidth
                size="small"
                value={sortBy}
                onChange={({ target: { value } }): void => {
                  sortCard(value)
                }}
              >
                {sortByTypes.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} style={{ marginLeft: '10px' }}>
            <Box mt={5} display="flex-end">
              <TextField
                id="buyerIdFilter"
                name="buyerIdFilter"
                select
                label="Buyer:"
                variant="filled"
                fullWidth
                size="small"
                value={buyerIdSeleted}
                onChange={({ target: { value } }): void => {
                  setBuyerIdSeleted(value)
                  handleBcoSelected(value, sellerIdSeleted)
                }}
              >
                {buyerAndSellerBcoData?.buyerList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} style={{ marginLeft: '10px' }}>
            <Box mt={5} display="flex-end">
              <TextField
                id="sellerIdFilter"
                name="sellerIdFilter"
                select
                label="Seller:"
                variant="filled"
                fullWidth
                size="small"
                value={sellerIdSeleted}
                onChange={({ target: { value } }): void => {
                  setSellerIdSeleted(value)
                  handleBcoSelected(buyerIdSeleted, value)
                }}
              >
                {buyerAndSellerBcoData?.sellerList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
        </Grid>
      )
    }
    return null
  }
  const getSummaryTextBco = () => {
    if (bcoValueData?.data && isSeller) {
      return (
        <>
          {' '}
          <Typography
            variant="h1"
            style={{
              fontWeight: 200,
              marginTop: '5px',
              marginBottom: '10px',
              marginLeft: '10px',
            }}
          >
            My Buyers - BCO Comparison{' '}
          </Typography>
        </>
      )
    }

    return (
      <Typography
        variant="h1"
        style={{
          fontWeight: 200,
          marginTop: '5px',
          marginBottom: '10px',
          marginLeft: '10px',
        }}
      >
        All BCOs{' '}
      </Typography>
    )
  }
  const renderBcoData = () => {
    return (
      <>
        {bcoValueData?.data && (
          <>
            {getSummaryTextBco()}
            {renderInternalButtons()}
          </>
        )}
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
          <Box gridColumn="span 4">
            {bcoValueData?.data.map((bcoValue) => (
              <>
                <Box gridRow="span 4">
                  <BCOCards
                    isInternal={isInternal}
                    isBuyer={isBuyer}
                    idSeleted={buyerIdSeleted}
                    onSelect={handleBcoSelected}
                    bcoValue={bcoValue}
                  />
                </Box>
              </>
            ))}
          </Box>
          <Box gridColumn="span 8">
            <BCOPreview
              isSeller={isSeller}
              buyerIdGuid={buyerIdSeleted}
              sellerIdGuid={sellerIdSeleted}
            />
          </Box>
        </Box>
      </>
    )
  }

  const handleChange = (value: any): void => {
    setAgencyName(value)
  }

  const onDownload = () => {
    getUriExportComplaint()
  }

  const renderExportButton = () => {
    if (isInternal || isSeller) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setShowFilterCompliance(true)
            setAgencyName('')
            setStartDate(null)
            setEndDate(null)
            setSellerFilter('')
          }}
        >
          Export to Excel
        </Button>
      )
    }
    return null
  }
  const renderCreateComplaintButton = () => {
    return (
      <Button
        data-cy="create-new-complaint-button"
        onClick={onCreateComplaint}
        color="primary"
        variant="contained"
        style={{ marginRight: '5px' }}
      >
        Create a new complaint
      </Button>
    )
  }

  const canCreateComplaint =
    (isInternal &&
      ability.can(PermissionCodeAccess.MarketPlace_Complaints, 'any')) ||
    ability.can(
      PermissionCodeAccess.MarketPlace_Complaints_CreateComplaint,
      'any'
    )

  return (
    <SectionColumn>
      <Content>
        <Typography variant="subtitle1">Complaint Manager</Typography>
        <SectionCards
          setCanAccessBco={setCanAccessBco}
          setAccessDenyBco={setAccessDenyBco}
          onSelect={handleCardsSelected}
          selected={cardSelected}
          filters={selectedFilters}
        />
      </Content>

      {cardSelected !== 'my-bcr' && cardSelected !== 'bco' && (
        <Content>
          <GridItemsHeader>
            <div>
              {canCreateComplaint && renderCreateComplaintButton()}
              {renderExportButton()}
              {FilterExportComplainceDialog()}
            </div>
          </GridItemsHeader>
          <Paper>
            <Box px={4} py={2} style={{ cursor: 'pointer' }}>
              {loadingComplaints && (
                <Skeleton variant="rectangular" width="100%" height={250} />
              )}
              {!loadingComplaints && (
                <DataTable
                  onRowClick={(e: any) => {
                    history.push(
                      getStandardUri(`${COMPLIANCE_INFO}/${e.dataItem.id}`)
                    )
                  }}
                  data={complaintData?.complaints || []}
                  total={complaintData?.total}
                  pageSize={gridState.take}
                  skip={gridState.skip}
                  take={gridState.take}
                  filter={gridState.filter}
                  sort={gridState.sort}
                  sortable
                  useFilterMenu
                  style={{
                    height: '90%',
                    maxHeight: '65vh',
                  }}
                  pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                  onDataStateChange={(e) => setGridState(e.dataState)}
                  gridColumns={GridColumns}
                />
              )}
            </Box>
          </Paper>
        </Content>
      )}
      {cardSelected === 'my-bcr' && <>{renderMyBco()}</>}
      {cardSelected === 'bco' && <>{renderBco()}</>}
    </SectionColumn>
  )
}

export default Compliance
